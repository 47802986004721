export default [
  {
    label: "19-06-2019 (DD-MM-YYYY)",
    value: "DD-MM-YYYY"
  },
  {
    label: "06-19-2019 (MM-DD-YYYY)",
    value: "MM-DD-YYYY"
  },
  {
    label: "19-06-2019 (DD-MM-YYYY)",
    value: "DD-MM-YYYY"
  },
  {
    label: "June 19th 2019 (MMMM Do YYYY)",
    value: "MMMM Do YYYY"
  }
];